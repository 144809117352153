<div style="height: 82px"></div>

<app-countdown></app-countdown>

<router-outlet></router-outlet>

<app-navbar
  (click)="signOutDialog()"
  matTooltip="Click to logout."
></app-navbar>

<div *ngIf="loading" class="loading-block-container-container">
  <div class="loading-block-container">
    <div class="loading-block"></div>
  </div>
</div>
