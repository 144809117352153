<table [dataSource]="datasource" class="mat-elevation-z8" mat-table matSort>
  <ng-container matColumnDef="username">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Name</th>
    <td *matCellDef="let element" mat-cell>{{ element.username }}</td>
  </ng-container>
  <ng-container matColumnDef="score">
    <th *matHeaderCellDef mat-header-cell mat-sort-header>Score</th>
    <td *matCellDef="let element" mat-cell>{{ element.score * 100 }}</td>
  </ng-container>

  <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
  <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
</table>
