<div class="gold-star-container">
  <ng-template [ngForOf]="selectedHymns" let-hymn ngFor>
    <img
      *ngIf="correct?.has(hymn)"
      class="gold-star"
      matTooltip="You correctly guessed {{ hymn }}!"
      src="/assets/gold-star.png"
    />
  </ng-template>
</div>

<!--<div>{{ selectedHymnsSet.size }}</div>-->
<alphabet-filter
  [data]="hymns"
  [withTemplate]="true"
  *ngIf="!disabled"
  height="700px"
  listClass="search-list"
  placeholder="Select Hymns"
  propAlphaOrder="name"
  propsSearch="name"
>
  <ng-template let-item>
    <div class="search-list-item">
      <mat-checkbox
        (change)="onCheckBox($event, item)"
        [checked]="selectedHymnsSet.has(item.name)"
        [disabled]="disabled"
      >
        <span>{{ item.name }}</span>
      </mat-checkbox>
    </div>
  </ng-template>
</alphabet-filter>
