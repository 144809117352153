<ng-template [ngIf]="sessionName === 'SaturdayMorningSession'">
  <div class="session-icon">
    <div class="session-icon-medium">Sat</div>
    <div class="session-icon-small">10 AM</div>
  </div>
</ng-template>
<ng-template [ngIf]="sessionName === 'SaturdayAfternoonSession'">
  <div class="session-icon">
    <div class="session-icon-medium">Sat</div>
    <div class="session-icon-small">2 PM</div>
  </div>
</ng-template>
<ng-template [ngIf]="sessionName === 'SaturdayEveningSession'">
  <div class="session-icon">
    <div class="session-icon-medium">Sat</div>
    <div class="session-icon-small">6 PM</div>
  </div>
</ng-template>
<ng-template [ngIf]="sessionName === 'SundayMorningSession'">
  <div class="session-icon">
    <div class="session-icon-medium">Sun</div>
    <div class="session-icon-small">10 AM</div>
  </div>
</ng-template>
<ng-template [ngIf]="sessionName === 'SundayAfternoonSession'">
  <div class="session-icon">
    <div class="session-icon-medium">Sun</div>
    <div class="session-icon-small">2 PM</div>
  </div>
</ng-template>
