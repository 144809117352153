<div class="fgc-flex-center">{{ getColorName() }}</div>
<app-swipe-select
  (selectedIndexChange)="selectedIndexChange($event)"
  [array]="choirColors"
  [disabled]="disabled"
  [imageHeight]="200"
  [imageWidth]="300"
  [selectedIndex]="selectedIndex"
  style="height: 215px"
></app-swipe-select>
<img
  *ngIf="correct?.has(selectedColor)"
  class="gold-star"
  matTooltip="You correctly guessed the choir's color!"
  src="/assets/gold-star.png"
>
