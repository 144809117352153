<a routerLink="/game">Back to Game</a>

<div>CORRECT_ANSWERS::</div>

<form>
  <app-session-selector
    [(selectedSession)]="selectedSession"
  ></app-session-selector>
  <mat-form-field appearance="fill">
    <mat-label>Type</mat-label>
    <mat-select [formControl]="selectedType">
      <mat-option
        [value]="type"
        *ngFor="let type of ['speaker', 'song', 'temple']"
        >{{ type | titlecase }}</mat-option
      >
    </mat-select>
  </mat-form-field>
  <ng-template [ngIf]="selectedType.value === 'speaker'">
    <div>
      <mat-form-field>
        <input
          [formControl]="selectedSpeaker"
          [matAutocomplete]="autoSpeakers"
          matInput
          name="speaker"
          placeholder="Pick Speaker"
          type="text"
        />
        <mat-autocomplete #autoSpeakers="matAutocomplete">
          <mat-option
            [value]="speaker"
            *ngFor="let speaker of filteredSpeakers | async"
            >{{ speaker }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <app-tie-select [(selectedTie)]="selectedTieColor"></app-tie-select>
    <button
      (click)="addSpeaker()"
      [disabled]="
        !selectedSession || !selectedTieColor || !selectedSpeaker.value
      "
      mat-raised-button
    >
      Add Speaker
    </button>
  </ng-template>
  <ng-template [ngIf]="selectedType.value === 'song'">
    <app-choir-swipe-select
      [(selectedColor)]="selectedChoirColor"
    ></app-choir-swipe-select>
    <div>
      <mat-form-field>
        <input
          [formControl]="selectedSong"
          [matAutocomplete]="autoHymns"
          matInput
          name="hymn"
          placeholder="Pick Hymn"
          type="text"
        />
        <mat-autocomplete #autoHymns="matAutocomplete">
          <mat-option
            [value]="song"
            *ngFor="let song of filteredSongs | async"
            >{{ song }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <button
      (click)="addSong()"
      [disabled]="
        !selectedSession || !selectedChoirColor || !selectedSong.value
      "
      mat-raised-button
    >
      Add Song
    </button>
  </ng-template>
  <ng-template [ngIf]="selectedType.value === 'temple'">
    <div>
      <mat-form-field>
        <input
          [formControl]="selectedStateOrCountry"
          [matAutocomplete]="autoTemples"
          matInput
          name="temple"
          placeholder="Pick Temple"
          type="text"
        />
        <mat-autocomplete #autoTemples="matAutocomplete">
          <mat-option
            [value]="place"
            *ngFor="let place of filteredStateOrCountries | async"
            >{{ place }}</mat-option
          >
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <button
      (click)="addTemple()"
      [disabled]="!selectedStateOrCountry.value"
      mat-raised-button
    >
      Add Temple
    </button>
  </ng-template>
</form>

<div>
  <button (click)="freezeScores()" color="danger" mat-raised-button>
    Freeze Scores
  </button>
</div>
