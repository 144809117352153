<div class="map-container">
  <div #map class="map-holder" style="height: 100%; width: 100%"></div>
</div>

<div class="gold-star-container">
  <ng-template [ngForOf]="selectedRegionNames" let-region ngFor>
    <img
      *ngIf="correct?.has(region)"
      class="gold-star"
      matTooltip="You correctly guessed {{ region }}!"
      src="/assets/gold-star.png"
    />
  </ng-template>
</div>
