<div [style.height.px]="imageHeight" style="width: 100%">
  <swiper
    (indexChange)="onIndexChange($event)"
    (swiperClick)="onSwiperClick($event)"
    [disabled]="disabled"
    [index]="selectedIndex"
    [style.height.px]="imageHeight"
  >
    <ng-template [ngForOf]="array" let-index="index" let-item ngFor>
      <div
        [style.height.px]="imageHeight"
        [style.width.px]="imageWidth"
        style="text-align: center; overflow: hidden"
      >
        <img
          (click)="updateSelectedIndex(index)"
          [alt]="item.name"
          [height]="imageHeight"
          [src]="item.src"
          [title]="item.name"
          style="border-radius: 50%"
        />
      </div>
    </ng-template>
  </swiper>
</div>
