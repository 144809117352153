<div style="padding: 4px 12px">
  <h1 class="fgc-flex-center">Welcome!</h1>

  <a *ngIf="userHolder.watchIsAdmin() | async" routerLink="/admin"
    >Admin View</a
  >

  <div>How to play:</div>
  <div>
    Tap a section below to make a guess of who you think will be speaking, which
    session, what color tie he will be wearing (if applicable) and so on.
  </div>
</div>

<mat-accordion [multi]="false">
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>First Presidency</mat-panel-title>
      <mat-panel-description>Who will speak?</mat-panel-description>
    </mat-expansion-panel-header>
    <div class="first-presidency">
      <div class="instructions">Tap a face to guess</div>
      <div class="first-presidency-prophet">
        <app-person-selector-container
          [correct]="gameData.corrects?.russellMNelson"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.firstPresidency.russellMNelson"
        ></app-person-selector-container>
      </div>
      <div class="first-presidency-counselors">
        <app-person-selector-container
          [correct]="gameData.corrects?.dallinHOaks"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.firstPresidency.dallinHOaks"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.henryBEyring"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.firstPresidency.henryBEyring"
        ></app-person-selector-container>
      </div>
    </div>
  </mat-expansion-panel>

  <!--  <mat-expansion-panel>-->
  <!--    <mat-expansion-panel-header>-->
  <!--      <mat-panel-title> First Presidency </mat-panel-title>-->
  <!--      <mat-panel-description> Who is conducting? </mat-panel-description>-->
  <!--    </mat-expansion-panel-header>-->
  <!--    <div class="first-presidency">-->
  <!--      <app-person-selector-container-->
  <!--        [person]="gameData.answers.firstPresidency.russellMNelson"-->
  <!--      ></app-person-selector-container>-->
  <!--      <app-person-selector-container-->
  <!--        [person]="gameData.answers.firstPresidency.henryBEyring"-->
  <!--      ></app-person-selector-container>-->
  <!--      <app-person-selector-container-->
  <!--        [person]="gameData.answers.firstPresidency.dallinHOaks"-->
  <!--      ></app-person-selector-container>-->
  <!--    </div>-->
  <!--  </mat-expansion-panel>-->

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Apostles</mat-panel-title>
      <mat-panel-description>Who will speak?</mat-panel-description>
    </mat-expansion-panel-header>
    <div class="fgc-flex-center">
      <div class="apostles">
        <app-person-selector-container
          [correct]="gameData.corrects?.mRussellBallard"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.mRussellBallard"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.jeffreyRHolland"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.jeffreyRHolland"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.dieterFUchtdorf"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.dieterFUchtdorf"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.davidABednar"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.davidABednar"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.quentinLCook"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.quentinLCook"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.dToddChristofferson"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.dToddChristofferson"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.neilLAndersen"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.neilLAndersen"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.ronaldARasband"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.ronaldARasband"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.garyEStevenson"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.garyEStevenson"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.daleGRenlund"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.daleGRenlund"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.gerritWGong"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.gerritWGong"
        ></app-person-selector-container>
        <app-person-selector-container
          [correct]="gameData.corrects?.ulissesSoarses"
          [disabled]="timeDisabledService.isDisabled()"
          [person]="gameData.answers.apostles.ulissesSoarses"
        ></app-person-selector-container>
      </div>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Choir Colors</mat-panel-title>
      <mat-panel-description
        >What will the choir be wearing?</mat-panel-description
      >
    </mat-expansion-panel-header>
    <div class="choir-colors">
      <div class="instructions">Swipe left or right</div>
      <ng-template [ngForOf]="sessions" let-session ngFor>
        <div class="fgc-flex-center">
          What color will the choir be wearing during
          <b>{{ sessionNames[session] }}</b>
        </div>
        <app-choir-swipe-select
          [(selectedColor)]="gameData.answers.choirColors[session]"
          [correct]="
            gameData.corrects?.choir
              ? gameData.corrects?.choir[session]?.choirColor
              : null
          "
          [disabled]="timeDisabledService.isDisabled()"
        ></app-choir-swipe-select>
      </ng-template>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Hymns</mat-panel-title>
      <mat-panel-description>What will be sung?</mat-panel-description>
    </mat-expansion-panel-header>
    <div class="hymns">
      <div>
        I think the hymns to be sung will be: (Max 10)
        {{ gameData.answers.selectedHymns?.join(", ") }}
      </div>
      <app-hymn-select
        [(selectedHymns)]="gameData.answers.selectedHymns"
        [correct]="gameData.corrects?.songs"
        [disabled]="timeDisabledService.isDisabled()"
      ></app-hymn-select>
    </div>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>Temples</mat-panel-title>
      <mat-panel-description
        >Where will new temples be built?</mat-panel-description
      >
    </mat-expansion-panel-header>
    <div class="temples">
      <div class="instructions">
        Pinch or spread to zoom in or out. Tap to select.
      </div>

      <div>I think these states of the U.S.A will get new temples: Max 5</div>
      <app-temple-map
        [(selectedRegionNames)]="gameData.answers.newTemples.usa"
        [correct]="gameData.corrects?.temple"
        [disabled]="timeDisabledService.isDisabled()"
        mode="usa"
      ></app-temple-map>
      <div>{{ gameData.answers.newTemples.usa?.join(", ") }}</div>

      <div>
        I think these countries will get new temples (Not including the U.S.A.):
        Max 5
      </div>
      <app-temple-map
        [(selectedRegionNames)]="gameData.answers.newTemples.world"
        [correct]="gameData.corrects?.temple"
        [disabled]="timeDisabledService.isDisabled()"
        mode="world"
      ></app-temple-map>
      <div>{{ gameData.answers.newTemples.world?.join(", ") }}</div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<app-scoreboard></app-scoreboard>

<div *ngIf="gameSocket.isLoading()" class="loading-block-container-container">
  <div class="loading-block-container">
    <div class="loading-block"></div>
  </div>
</div>
